@import "~react-image-gallery/styles/css/image-gallery.css";
* {
  text-decoration: none !important;
}
/* iframe {
  z-index: -1 !important;
} */
/*  */
.car-height .el-carousel__container {
  height: 600px !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  z-index: 99 !important;
  background: #fff;
  display: none;
  position: absolute;
  background-attachment: #f9f9f9;
  min-width: 168px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.flexcenter {
  display: flex;
  align-items: center;
}
.themefamily {
  font-family: "Poppins", sans-serif !important;
}
.c-dropdown {
  color: #3e3e3e;
  font-size: 18px;
  font-weight: 500;
}
.tcss {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
  color: #3e3e3e;
  padding: 8px;
}
.tcss:hover {
  background-color: #f4f5f6;
}
.sidebarfixcls {
  position: fixed !important;
}
.hoverText1 {
  position: relative;
}
.hoverText1:before {
  content: "+";
  /* visibility: hidden;
  opacity: 0; */
  width: 34px;
  height: 34px;
  background: #2b3737;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  border-radius: 999px;
  cursor: pointer;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0px;
}

.sidebarclass {
  width: 100% !important;
  z-index: 99 !important;
  background: #0000006e !important;
}
.sideheader {
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.flexsetjustify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px !important;
  background: #222 !important;
  color: #fff !important;
}
.px-20px {
  padding-left: 20px;
  padding-right: 20px;
}
.pt-10px {
  padding-top: 10px;
}
.tooltip-w {
  width: auto !important;
}

.submitbutton {
  width: 50%;
  background: var(--qtheme-color-primary) !important;
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin: auto;
  height: 45px;
}
.inputcss {
  width: 100%;
  height: 38px;
  padding-left: 17px;
  padding-left: 17px;

  border: 1px solid #80808069;
  border-radius: 4px;
}
.mt-30px {
  margin-top: 30px;
}
.imageCss {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  border: 2px dashed #80808069;
  border-radius: 4px;
}
.menucss-al {
  margin-right: 30px;
}
.mb-5px {
  margin-bottom: 5px;
}
.my-0px {
  margin-top: 0px;
  margin-bottom: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
/*  */
.stop-abcall {
  position: inherit !important;
}
.stop-abchild {
  position: inherit !important;
}
.qkl-header {
  position: relative;
  z-index: 99;
}
.w-full {
  width: 100% !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  margin-right: 50%;
  height: 710px;
  border-radius: 0px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100px;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 55px !important;
  height: 55px !important;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 55px !important;
  height: 55px !important;
}
.text-white {
  color: #fff !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
}
.font-semibold {
  font-weight: 600 !important;
}

.c-flex {
  display: flex;
}
.mr-10px {
  margin-right: 10px;
}

.mr-15px {
  margin-right: 15px;
}
.chips-select {
  background: #ddd;
  padding: 5px 14px;
  font-size: 13px;
  border-radius: 50px;
  font-weight: 600;
  color: #5a5a5a;
  cursor: pointer;
}
.addtocart-img {
  height: 148px !important;
  object-fit: cover !important;
  border-radius: 6px;
}
.borderltb {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

/* form */

.error {
  color: red;
}

.myForm {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 0.8em;
  width: 30em;
  padding: 1em;
  border: 1px solid #ccc;
}

.myForm * {
  box-sizing: border-box;
}

.myForm fieldset {
  border: none;
  padding: 0;
}

.myForm legend,
.myForm label {
  padding: 0;
  font-weight: bold;
}

.myForm label.choice {
  font-size: 0.9em;
  font-weight: normal;
}

.myForm label {
  text-align: left;
  display: block;
}

.myForm input[type="text"],
.myForm input[type="tel"],
.myForm input[type="email"],
.myForm input[type="datetime-local"],
.myForm select,
.myForm textarea {
  float: right;
  width: 60%;
  border: 1px solid #ccc;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 0.9em;
  padding: 0.3em;
}

.myForm textarea {
  height: 100px;
}

.myForm input[type="radio"],
.myForm input[type="checkbox"] {
  margin-left: 40%;
}

.myForm button {
  padding: 1em;
  border-radius: 0.5em;
  background: #eee;
  border: none;
  font-weight: bold;
  margin-left: 40%;
  margin-top: 1.8em;
}

.myForm button:hover {
  background: #ccc;
  cursor: pointer;
}
.c-inputcs {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #8080809c;
  padding: 10px;
}
.mx-tr {
  margin: 0px 27px 0px 27px;
}
.pt-10pxt {
  padding-top: 10px;
  font-size: 14px;
}
.sidebar-overlay {
  width: 35%;
  margin-left: auto;
  background: #fff;
  height: 100%;
  
}

@media (max-width: 640px) {
  .sidebar-overlay  {
    width: 75%;
  }
}
.line-clamp1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-8f8f8f {
  color: #8f8f8f;
}
.font-semibold {
  font-weight: 600;
}
.lheight-25px {
  line-height: 25px;
}

.c-selectcs {
  padding: 3px 10px;
  border: 1px solid #80808066;
}
.elshadow-md {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.cWFcmg {
  margin-bottom: 1rem;
}
.kXYYEM {
  margin-top: 0px !important;
}
.justifybetween {
  justify-content: space-between;
}
.bcolor-input {
  border-color: #8484843d !important;
  font-weight: 400;
}
.bcolor-input:focus {
  border-color: #111 !important;
  font-weight: 400;
}
.h-90px {
  height: 90px;
}
.textblack {
  color: #000 !important;
}
.font-bold {
  font-weight: 500 !important;
}
.rounded-6px {
  border-radius: 6px;
}
.select-Filter {
  padding: 10px 10px;
  border-radius: 6px;
}
@media (min-width: 576px) {
  .hidemin-time {
    display: none;
  }
  @media (min-width: 576px) {
    .hidemin-time {
      display: none;
    }
  }
  @media (max-width: 640px) {
   
    .hidemax-time {
      display: none;
    }
  }
  .timeline-card-content {
    background-color: #000 !important;
  }
  .timeline-circle {
    border-radius: 16px;
  }
  .hidemax-time {
    display: none;
  }
}
.timeline-card-content {
  background-color: #000 !important;
}
.timeline-circle {
  border-radius: 16px;
}
.timeline-circle.active::after {
  background: #000 !important;
  border-radius: 20px;
}
.pd-12px {
  padding: 12px !important;
}
.css-choose {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.bg-f4f5f6 {
  background-color: #f4f5f6;
}
.testm-cs {
  width: 168px;
  height: 228px;
}
.pd-12px {
  padding: 12px !important;
}
.zindex-issue {
  position: relative;
  z-index: 1;
}
.bottom_right{
  position: fixed;
  bottom: 21px;
  right: 45px;
  padding: 15px 20px;
  background-color: rgb(49, 155, 49);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index:9;
}


.hover:hover {
  color: var(--qtheme-color-primary) !important;
}