.swiper-wrapper {
  display: flex !important;
}


.clr-primary {
  color: var(--qtheme-color-primary) !important;
}

.mr-38px {
  margin-right: 38px;
  @media (max-width: 640px) {
    margin-right: 0px;
  }
}

.margin-nav {
  margin-top: 93px !important;

  @media (max-width: 768px) {
    margin-top: 69px !important;
  }
}
//navbar
.widthsm {
  @media (max-width: 640px) {
    width: 100% !important;
  }
  @media (max-width: 820px) and (min-width: 641px) {
    width: 100% !important;
  }
}

@media (max-width: 768px) and (min-width: 641px) {
  .md-space-between {
    justify-content: space-between;
  }
}
.swiper-wrapper {
  display: flex !important;
}
.navbar-nav {
  padding: 0px !important;
}
.navbar-nav {
  @media (max-width: 768px) and (min-width: 641px) {
    display: block !important;
  }
  @media (max-width: 640px) {
    display: block !important;
  }
}
.underlino {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
.underlino-blck {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
a#nav-dropdown-dark-example {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
.navbar-toggler {
  background-color: #ececec !important;
  font-size: 15px !important;
}

@media only screen and (min-width: 600px) {
  .eventsNav {
    width: 65% !important;
    background: #fff !important;
    position: sticky !important;
    top: 69px !important;
    padding: 20px !important;
    margin: auto !important;
    left: 0% !important;
  }
}

.new-drop .dropdown-menu {
  position: absolute !important;
  top: 45px !important;
  height: none !important;
  background: #fff !important;
  left: -45px !important;
}

.mt-98px {
  margin-top: 98px;
  @media (max-width: 820px) and (min-width: 641px) {
    margin-top: 72px;
  }
  @media (max-width: 640px) {
    margin-top: 61px;
  }
}

.zindex{
  z-index: 99;
}

.fixed {
  position: fixed !important;
}

.item:nth-child(4n-7) {
  grid-column: span 2 / span 2;
  @media (max-width: 820px) and (min-width: 641px) {
    grid-column: span 1 / span 1;
  }
  @media (max-width: 640px) {
    grid-column: span 1 / span 1;
  }
  
}

.item:nth-child(4n-4) {
  grid-column: 2 / span 2;
  @media (max-width: 820px) and (min-width: 641px) {
    grid-column: 1 / span 1;
  }
  @media (max-width: 640px) {
    grid-column: 1 / span 1;
  }
 
}

.gutter {
  --bs-gutter-x: 0px !important;
}


.h-600px {
  height: 600px;
  @media (max-width: 640px) {
    height: 580px;
  }
  // background-color:"linear-gradient(0deg,rgba(255, 255, 255, 0.05) 0%,rgba(255, 255, 255, 0.44) 100%),rgba(0, 0, 0, 0) "
}
.h-350px {
  height: 350px;
  @media (max-width: 640px) {
    height: 350px;
  }
  // background-color:"linear-gradient(0deg,rgba(255, 255, 255, 0.05) 0%,rgba(255, 255, 255, 0.44) 100%),rgba(0, 0, 0, 0) "
}
.h-305px {
  height: 305px;
  @media (max-width: 640px) {
    height: 305px;
  }
  // background-color:"linear-gradient(0deg,rgba(255, 255, 255, 0.05) 0%,rgba(255, 255, 255, 0.44) 100%),rgba(0, 0, 0, 0) "
}

.linear{
  background: linear-gradient(0deg,rgba(255, 255, 255, 0.05) 0%,rgba(255, 255, 255, 0.44) 100%),rgba(0, 0, 0, 0) !important;
}

.filter {
  filter: brightness(0.5);
}

//pagination
.active>.page-link1, .page-link1.active{
  background-color: orangered;
  border-color: orangered;
  color: white;
  z-index: 3;
}

.page-link1 {
  color: orangered;
}